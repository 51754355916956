<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-room-menu></admin-room-menu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>

                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    휴업 설정
                                    <v-btn
                                            color="error"
                                            class="mx-2 white&#45;&#45;text center-block float-right"
                                            x-small
                                            @click="btnClick(1)"
                                    >
                                        추가
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b" style="min-width: 900px;">
                                        <colgroup>
                                            <!--<col style="width:16%"/>-->
                                            <col style="width:10%"/>
                                            <col style="width:33%"/>
                                            <col style="width:33%"/>
                                            <col style="width:15%"/>
                                            <!--<col style="width:10%"/>-->
                                            <col style="width:8%"/>
                                        </colgroup>
                                        <tr>
                                            <!--<th>번호</th>-->
                                            <th>번호</th>
                                            <th>시작일</th>
                                            <th>종료일</th>
                                            <th>적용</th>
                                            <!--<th>저장</th>-->
                                            <th>삭제</th>
                                        </tr>
                                        <tr v-for="(item, i) in stopList"
                                            :key=i
                                        >
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                <div style="display: flex;">
                                                    <select v-model="item.startDateY" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in yearList" :value="n">{{n}}</option>
                                                    </select>
                                                    <span class="mx-1">-</span>
                                                    <select v-model="item.startDateM" style="width: 25%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 12" :value="n">{{n}}월</option>
                                                    </select>
                                                    <span class="mx-1">-</span>
                                                    <select v-model="item.startDateD" style="width: 25%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 31" :value="n">{{n}}일</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div style="display: flex;">
                                                    <select v-model="item.endDateY" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in yearList" :value="n">{{n}}</option>
                                                    </select>
                                                    <span class="mx-1">-</span>
                                                    <select v-model="item.endDateM" style="width: 25%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 12" :value="n">{{n}}월</option>
                                                    </select>
                                                    <span class="mx-1">-</span>
                                                    <select v-model="item.endDateD" style="width: 25%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 31" :value="n">{{n}}일</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <select v-model="item.useYn" style="width: 100%">
                                                    <option value="Y">적용</option>
                                                    <option value="N">미적용</option>
                                                </select>
                                            </td>
                                            <!--<td>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-4 white&#45;&#45;text center-block float-right"
                                                        x-small
                                                        @click="update(item, 2)"
                                                >
                                                    저장
                                                </v-btn>
                                            </td>-->
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-4 white--text center-block float-right"
                                                        x-small
                                                        @click="update(item, 1)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                    <v-col>
                                        <!--<v-btn
                                                color="error"
                                                class="mx-2 white&#45;&#45;text center-block float-right"
                                                x-small
                                                @click="btnClick(1)"

                                        >
                                            추가
                                        </v-btn>-->
                                        <v-btn
                                                color="primary"
                                                class="mx-4 white--text center-block"
                                                @click="btnClick(2)"
                                        >
                                            저장
                                        </v-btn>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>

    import AdminRoomMenu from '../../components/AdminRoomMenu'

    export default {
        name: 'AdminStopList',
        components: {
            AdminRoomMenu
        },
        data: () => ({
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,
            stopList: [],
            tmpStopId: 0,
            dialog: false,
            yearNow: "",
            yearList: [],

        }),
        created: function () {
            // 맨위로
            if ( this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            let now = new Date();	// 현재 날짜 및 시간
            let year = now.getFullYear();	// 연도


            for (let i = 0; i < 5; i++) {

                this.yearList.push(year+i);
            }

            this.yearNow = year;


            this.getList({});
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("admin/getStopList", {})
                    .then((resp) => {
                        setTimeout(() => {
                            this.stopList = resp.message;
                            this.setStopInfo();

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            setStopInfo() {

                for (let i = 0; this.stopList.length > i; i++) {
                    this.stopList[i].startDateY = Number(this.stopList[i].stopStartDate.split("-")[0]);
                    this.stopList[i].startDateM = Number(this.stopList[i].stopStartDate.split("-")[1]);
                    this.stopList[i].startDateD = Number(this.stopList[i].stopStartDate.split("-")[2]);

                    this.stopList[i].endDateY = Number(this.stopList[i].stopEndDate.split("-")[0]);
                    this.stopList[i].endDateM = Number(this.stopList[i].stopEndDate.split("-")[1]);
                    this.stopList[i].endDateD = Number(this.stopList[i].stopEndDate.split("-")[2]);

                }

            },

            btnClick(val) {

                let _this = this;

                switch (val) {
                    case 0 : {   // 주말 저장


                        break;
                    }
                    case 1 : {   // 할인 추가

                        let formdata = {
                            stopType: 0,
                            stopPrice: "0",
                            stopStartDate: _this.yearNow+"-01-01",
                            stopEndDate: _this.yearNow+"-01-01",
                            useYn: 'Y',
                            lstModPrs: "admin",
                            tmpStopId: this.tmpStopId,
                            startDateY: _this.yearNow,
                            startDateM: "1",
                            startDateD: "1",
                            endDateM: "1",
                            endDateD: "1",
                        };

                        this.stopList.push(formdata);

                        this.tmpStopId += this.tmpStopId + 1;

                        break;
                    }
                    case 2 : {   // 휴업정 저장
                        if (!confirm("휴업정보 설정을 저장 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트

                            return this.$store.dispatch("admin/deleteStops", {isDel: 'true'})
                                .then((resp) => {
                                    setTimeout(() => {
                                        let index = 0;
                                        let listIndex = _this.stopList.length;

                                        _this.dialog = true;

                                        _this.stopList.forEach(function (e) {

                                            setTimeout(() => {

                                                _this.$store.dispatch("admin/insertStop", e)
                                                    .then((resp) => {
                                                        index += 1;

                                                        if (index === listIndex) {

                                                            setTimeout(() => {
                                                                //location.href = "/admin/default?comnGubun=" + this.comnInfo.comnGubun
                                                                _this.getList({});
                                                                _this.dialog = false;
                                                                alert("저장했습니다.");
                                                            }, 300)
                                                        }
                                                    })
                                                    .catch((err) => {
                                                    });

                                            }, 500)
                                        });

                                    }, 300)
                                })
                                .catch((err) => {
                                })


                        }

                        break;
                    }
                }

            },
            update(val, isUpdate) {
                switch (isUpdate) {

                    case 1 : {
                        if (!confirm("휴업설정을 삭제 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (val.tmpStopId != undefined) {

                                for (let i = 0; this.stopList.length > i; i++) {

                                    if (this.stopList[i].tmpStopId === val.tmpStopId) {
                                        this.stopList.splice(i, 1);
                                    }
                                }
                            } else {
                                for (let i = 0; this.stopList.length > i; i++) {

                                    if (this.stopList[i].stopId === val.stopId) {
                                        this.stopList.splice(i, 1);
                                    }
                                }

                                /*this.$store.dispatch("admin/deleteStop", val)
                                    .then((resp) => {
                                        setTimeout(() => {
                                            //location.href = "/admin/default?comnGubun=" + this.comnInfo.comnGubun
                                            this.getList({});
                                            this.dialog = false;
                                            alert("삭제했습니다.");
                                        }, 300)
                                    })
                                    .catch((err) => {
                                    });*/
                            }
                        }
                        break;
                    }
                    case 2 : {   // 저장
                        if (!confirm("휴업설정을 저장 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {

                            this.dialog = true;

                            this.$store.dispatch("admin/updateStop", val)
                                .then((resp) => {
                                    setTimeout(() => {
                                        this.getList({});
                                        this.dialog = false;
                                        alert("저장했습니다.");
                                    }, 300)

                                })
                                .catch((err) => {
                                });

                        }

                        break;
                    }

                }

            },
            selectChange(event, item) {

                item.stopStartDate = this.numberPad(item.startDateY, 4) + "-" + this.numberPad(item.startDateM, 2) + "-" + this.numberPad(item.startDateD, 2);
                item.stopEndDate = this.numberPad(item.endDateY, 4) + "-" + this.numberPad(item.endDateM, 2) + "-" + this.numberPad(item.endDateD, 2);

            },
            numberPad(n, width) {
                n = n + '';
                return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
            },

        },
        mounted() {


        },

    }
</script>

<style>

</style>


